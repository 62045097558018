<section class="container-fluid bg-primary" id="about">
    <div class="text-center">
      <h2 class="section-heading text-white">About</h2>
      <br>
      <br>
      <div class="row">
        <div class="col-md-8 text-center">
          <p class="text-faded mb-7">
            Brian Alan Hager is an American singer/songwriter, guitarist and vocalist based in Cleveland, Ohio.
            He performs solo or with The Brian Alan Hager Band in Ohio, PA, Indiana and other parts of the eastern US.
            He is a soulful and tasteful music artist, whose passionate "one man band" performances include
            reinterpreted covers and original material. The Brian Alan Hager Band performs reinterpreted versions of his material
            in a jam band type format. His music features tasteful acoustic and electric guitars, synthesizers and guitar melodies
            resulting in a blend of soulful indie pop, rock, blues and alternative music. His style crosses genres
            and some of his influences include John Mayer, The Black Keys, The Black Crowes, Arctic Monkeys, Dawes, The Record Company and The Rolling Stones.
            </p>
              <p class="text-faded mb-7">
                Brian's first release, "The Condition of Things", released in January of 2022, is guitar and synth-based music that fuses elements of
                alternative, indie, and rock. The lyrics touch on personal struggles, negative and positive thinking, the loss of loved ones,
                relationship issues, and spiritual healing. His second album "Rock and Soul" was released in phases throughout 2023 and 2024 and continue on similar topics with
                more of a rock, electric guitar driven sound mixed with elements of soul music. All his music is available from <a href="https://linktr.ee/brianalanhager">online streaming services</a>.
                </p>
                <p class="text-faded mb-7">
                His unique live performances mix acoustic, electric and bass guitar by recording loops on the fly,
                then applying vocals over those loops. This technique, blended with percussion loops create a full sound,
                which may also include improvised blues, rock and jazz guitar solos. His live shows also include popular
                pop and rock cover songs from all eras <a href="#booking" (click)="openModalWithClass(template)">(Sample Cover Song List)</a>.
                  If you'd like to learn more about booking, Brian can be reached by sending an email to
                  <a href="mailto:brianalanhager@gmail.com">brianalanhager@gmail.com</a>
                </p>
        </div>
        <div class="col-md-4 text-center">
          <a href="https://brianalanhager.square.site/"><img class="img-fluid img-thumbnail img-responsive" src="/assets/img/CDCoverTheConditionOfThingsVinyl_Front2.jpg"></a>
        </div>
      </div>
    </div>
  </section>
  <ng-template #template>
    <div class="modal-header">
       <h4 class="modal-title pull-left">Sample Song List</h4>
       <button type="button"
               class="close pull-right"
               (click)="modalRef.hide()">
           <span aria-hidden="true">&times;</span>
       </button>
    </div>

    <!-- Modal Body -->
    <app-song-list></app-song-list>
</ng-template>
