<section class="container-fluid container" id="press">
    <div class="container text-center mt-0">
      <h2 class="section-heading">Press</h2>
      <br>
        <div class="col-lg-8 mx-auto text-center">
          <p class="mb-5"><a href="https://www.clevescene.com/music/clevelands-brian-alan-hager-releases-first-segment-of-new-album-41816783">Cleveland's Brian Alan Hager Releases First Segment of New 'Rock and Soul' Album</a>
          <br> - Cleveland Scene Magazine - Jeff Niesel</p>
          <p class="mb-5"><a href="https://coolcleveland.com/2023/04/guitarist-songwriter-brian-alan-hager-releases-first-batch-of-a-series-of-new-tunes/">Guitarist/Songwriter Brian Alan Hager Releases First Batch of a Series of New Tunes</a>
          <br> - Cool Cleveland</p>
          <p class="mb-5"><a href="https://www.cleveland.com/entertainment/2022/01/cleveland-musician-brian-alan-hager-releases-debut-solo-album.html">Cleveland musician Brian Alan Hager releases debut solo album</a>
          <br> - cleveland.com - Annie Nickoloff</p>
          <p class="mb-5"><a href="https://bigbristein.podbean.com/e/level-up-cleveland-episode-64-brian-alan-hager/">Level Up Cleveland Episode 64 - Brian Alan Hager</a>
          <br> - Level Up Cleveland - Big Bri</p>
        </div>
      </div>
   
</section>

<section class="container-fluid bg-dark text-white" id="booking">
  <div class="container text-center mt-0">
    <h2 class="section-heading text-white">Booking</h2>
    <br>
      <p class="text-faded mb-5">Brian performs as a solo artist playing covers (Well known Rock and Pop songs) and/or original music, or with The Brian Alan Hager Band doing mostly original music (Indie Rock/Pop). Brian's solo performances utilize technologies that loop acoustic and electric guitars along with beats and vocals to create a full "one man band" sound.
      Check out his <a href="https://youtube.com/@brianalanhager">YouTube channel</a> to view videos of solo performances, or with his band.<br><br><a href="#booking" (click)="openModalWithClass(template)">(Sample Solo Covers Song List)</a>.<br><br>If you'd like to learn more, Brian can be reached by sending an email to <a href="mailto:brianalanhager@gmail.com">brianalanhager@gmail.com</a>.</p>
      <app-social></app-social>
      <br><br>
    </div>
 
</section>
<ng-template #template>
  <div class="modal-header">
     <h4 class="modal-title pull-left">Sample Song List</h4>
     <button type="button"
             class="close pull-right"
             (click)="modalRef.hide()">
         <span aria-hidden="true">&times;</span>
     </button>
  </div>

  <!-- Modal Body -->
  <app-song-list></app-song-list>
</ng-template>  


