<div class="container-fluid">
  <div class="row justify-content-md-center" >
    <div class="col ">
      <a class="nav-link" href="http://instagram.com/brianalanhager" target="_blank" alt="instagram">
        <i class="fa fa-instagram " style="font-size:25px;color:white;"></i>
      </a>
    </div>
    <div class="col">
      <a class="nav-link" href="http://facebook.com/brianalanhager" target="_blank" alt="facebook">
        <i class="fa fa-facebook " style="font-size:25;color:white;"></i>
      </a>
    </div>
    <div class="col">
      <a class="nav-link" href="https://open.spotify.com/artist/7CnSpNEPorm9nQ0Eb7yqCM?si=GDnpMAcPTlyNqAthc7OjqQ" target="_blank" alt="spotify">
        <i class="fa fa-spotify" style="font-size:25;color:white;"></i>
      </a>
    </div>
    <div class="col">
      <a class="nav-link" href="https://music.apple.com/us/artist/brian-alan-hager/1453544466" target="_blank" alt="apple music">
        <i class="fa fa-apple" style="font-size:25;color:white;"></i>
      </a>
    </div>
    <div class="col">
      <a class="nav-link" href="https://www.youtube.com/channel/UCx5whe5ndLRFCyP6FI_O3Hw" target="_blank" alt="you tube">
        <i class="fa fa-youtube" style="font-size:25;color:white;"></i>
      </a>
    </div>
  </div>
</div>
