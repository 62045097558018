<section id="merch" class="container-fluid bg-dark text-white">
  <div class="container text-center">
    <h2 class="mb-4">Merch</h2>
    <div class="text-center row mb-4">
      <div class="container text-center col-sm">
        <a href="https://brianalanhager.square.site/" target="_blank"><img class="img-fluid img-responsive" height="250" width="250" src="/assets/img/TheConditionOfThings.jpg" frameborder="0"></a>
        <div class="container text-center mb-4">
        "The Condition Of Things" CD<br>
        $10.00
        </div>
      </div>
      <div class="text-center col-sm">
        <a href="https://brianalanhager.square.site/" target="_blank"><img class="img-fluid img-responsive" height="250" width="250" src="/assets/img/SpiritSway.jpg" frameborder="0"></a>
        <div class="container text-center mb-4">
        "Spirit Sway / I See Anges" Singles CD<br>
        $5.00
        </div>
      </div>
      <div class="text-center col-sm">
        <a href="https://brianalanhager.square.site/" target="_blank"><img class="img-fluid img-responsive" height="250" width="250" src="/assets/img/I_Dont_Feel.PNG" frameborder="0"></a>
        <div class="container text-center mb-4">
        "I Don't Feel / The Race" Singles CD<br>
        $5.00
        </div>
      </div>
      <div class="text-center col-sm">
        <a href="https://brianalanhager.square.site/" target="_blank"><img class="img-fluid img-responsive" height="250" width="250" src="/assets/img/TheSinglesFront.jpg" frameborder="0"></a>
        <div class="container text-center mb-4">
        "Singles" Singles CD<br>
        $10.00
        </div>
      </div>
    </div>
  </div>
</section>


