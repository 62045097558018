import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TopBarComponent } from './top-bar/top-bar.component';
import { ProductListComponent } from './product-list/product-list.component';
import { EventListComponent } from './event-list/event-list.component';
import { HomeComponent } from './home/home.component';
import { ProductAlertsComponent } from './product-alerts/product-alerts.component';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { TopNavComponent } from './top-nav/top-nav.component';
import { SingleMainPageComponent } from './single-main-page/single-main-page.component';
import { AboutComponent } from './about/about.component';
import { VideoComponent } from './video/video.component';
import { MusicComponent } from './music/music.component';
import { PhotosComponent } from './photos/photos.component';
import { DiscographyComponent } from './discography/discography.component';
import { BookingComponent } from './booking/booking.component';
import { AngularFireModule } from 'angularfire2';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { environment } from '../environments/environment';
import { FormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { SocialComponent } from './social/social.component';
import { RouterModule } from '@angular/router';
import { PurchaseCDComponent } from './purchase-cd/purchase-cd.component';
import { ShopComponent } from './shop/shop.component';
import { PromoComponent } from './promo/promo.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { SongListComponent } from './song-list/song-list.component';
import { BottomPlayerComponent } from './bottom-player/bottom-player.component';

@NgModule({
  declarations: [
    AppComponent,
    TopBarComponent,
    EventListComponent,
    ProductListComponent,
    HomeComponent,
    ProductAlertsComponent,
    ProductDetailsComponent,
    TopNavComponent,
    SingleMainPageComponent,
    AboutComponent,
    VideoComponent,
    MusicComponent,
    PhotosComponent,
    DiscographyComponent,
    BookingComponent,
    SocialComponent,
    PurchaseCDComponent,
    ShopComponent,
    PromoComponent,
    ScheduleComponent,
    SongListComponent,
    BottomPlayerComponent
  ],
  imports: [
    BrowserModule,
    ModalModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule.enablePersistence(),
    ReactiveFormsModule,
    AppRoutingModule,
    FormsModule,
    RouterModule.forRoot([
      { path: 'events', component: EventListComponent },
      { path: 'schedule', component: EventListComponent }
    ])
  ],
  providers: [],
  bootstrap: [AppComponent],
  exports: [SongListComponent, BottomPlayerComponent]
})
export class AppModule { }
