<div class="modal-body">
  <ul type="disc">
    <li>One - U2</li>
    <li>Gravity - John Mayer</li>
    <li>Ziggy Stardust - David Bowie</li>
    <li>You Can't Always Get What You Want - Rolling Stones</li>
    <li>Jumpin Jack Flash - Roling Stones</li>
    <li>Creep - Radiohead</li>
    <li>Under The Bridge - Red Hot Chilli Peppers</li>
    <li>High and Dry - Radiohead</li>
    <li>Today - Smashing Pumpkins</li>
    <li>Last Dance With Mary Jane - Tom Petty</li>
    <li>Surrender - Cheap Trick</li>
    <li>A Long December - Counting Crows</li>
    <li>Californication - Red Hot Chilli Peppers</li>
    <li>Into The Great Wide Open - Tom Petty</li>
    <li>All Along The Watchtower - Jimi Hendrix</li>
    <li>A Long December - Counting Crows</li>
    <li>The Scientist - Cold Play</li>
    <li>Man On The Moon - R.E.M.</li>
    <li>Purple Rain - Prince</li>
    <li>Glycerine - Bush</li>
    <li>Wild Horses - Rolling Stones</li>
    <li>6th Avenue Heartache - The Wallflowers</li>
    <li>Hey Delilah - Plain White T's</li>
    <li>Heroes - David Bowie</li>
    <li>Push - Matchbox Twenty</li>
    <li>Fly - Sugar Ray</li>
    <li>Running Down a Dream - Tom Petty</li>
    <li>The Joker - Steve Miller Band</li>
    <li>Never Tear Us Apart - INXS</li>
    <li>Losing My Religion - R.E.M.</li>
    <li>Ventura Highway - America</li>
    <li>Hey, Hey, What Can I Do - Led Zeppelin</li>
    <li>3AM - Matchbox Twenty</li>
    <li>Wonderwall - Oasis</li>
    <li>December - Collective Soul</li>
    <li>Every Rose Has It's Thorn - Posion</li>
    <li>Tequila Sunrise - Eagles</li>
    <li>Everlong - Foo Fighters</li>
    <li>Chasing Cars - Snow Patrol</li>
    <li>Black Hole Sun - Soundgarden</li>
    <li>Free Falling - Tom Petty</li>
    <li>Basket Case - Green Day</li>
    <li>Unwell - Matchbox Twenty</li>
    <li>Rocket Man - Elton John</li>
    <li>Budapest - George Ezra</li>
  </ul>
</div>
