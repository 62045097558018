<section id="video" class="container-fluid bg-dark text-white">
    <div class="text-center">
      <h2 class="mb-4">Video</h2>
      <i>Video of solo cover, original or band shows below. More video on <a href="https://youtube.com/@brianalanhager">YouTube channel</a></i>
      <br>
      <br>
      <h5 class="mb-4">Solo Original Music Shows</h5>
      <iframe title="Brian Alan Hager - Walk It Off (Quarry Hill Winery, 2021)" width="336" height="189" src="https://www.youtube.com/embed/Stq6E22WFRM?feature=oembed" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      <iframe title="Brian Alan Hager - Waiting On Nothing (Twisted Elk Brewery, 2022)" width="336" height="189" src="https://www.youtube.com/embed/j9wvLBGl4Ns?feature=oembed" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      <iframe title="Brian Alan Hager - Guitar Solo (Wolf Creek Winery, 2022)" width="336" height="189" src="https://www.youtube.com/embed/Jp8A72RnPkY?feature=oembed" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      <br>
      <br>      
      <h5 class="mb-4">The Brian Alan Hager Band</h5>
      <iframe title="The Brian Alan Hager Band - Waiting On Nothing - Live at BBC - 2024" width="336" height="189" src="https://www.youtube.com/embed/clz4DzzxDF8?feature=oembed" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      <iframe title="The Brian Alan Hager Band - A Dream - Live at BBC - 2024" width="336" height="189" src="https://www.youtube.com/embed/0ux8kmJjOZQ?si=ZBoN_tg3NhzNyz1Z" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      <iframe title="The Brian Alan Hager Band - Rehearal - 2024" width="336" height="189" src="https://www.youtube.com/embed/1_9PUeEzK4E?si=s2zCqLX2g_CJiP1f" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>      
      <br>
      <br>
      <h5 class="mb-4">Solo Cover Shows</h5>
      <iframe title="Brian Alan Hager - 2024 Covers Show Summary" width="336" height="189" src="https://www.youtube.com/embed/UEHgwCPi20g?feature=oembed" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>  
      <iframe title="Brian Alan Hager - 2023 Live highlights" width="336" height="189" src="https://www.youtube.com/embed/-KXxXBcp-w8?feature=oembed" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      <iframe title="Brian Alan Hager - 2022 Live highlights" width="336" height="189" src="https://www.youtube.com/embed/_i5ljcCLttY?si=O4IEE6AJEMtEbZ-X" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      <br>
      <br>      
      <!--<h5 class="mb-4">Music Videos</h5>
      <iframe title="Brian Alan Hager - Spirit Sway" width="336" height="189" src="https://www.youtube.com/embed/gLOMtDHKis8?feature=oembed" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      <iframe title="Brian Alan Hager - Waiting On Nothing" width="336" height="189" src="https://www.youtube.com/embed/3oJd6BcQ51k?feature=oembed" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      <iframe title="Brian Alan Hager - I See Angles" width="336" height="189" src="https://www.youtube.com/embed/VsvuuzyuLDI?feature=oembed" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      <iframe title="Brian Alan Hager - I Don't Feel" width="336" height="189" src="https://www.youtube.com/embed/c4xC0g6ciUY?feature=oembed" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      <iframe title="Brian Alan Hager - The Race" width="336" height="189" src="https://www.youtube.com/embed/70NDWHmlBhM?feature=oembed" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      <br>
      <br> -->
      <h5 class="mb-4">Interviews</h5>
      <iframe title="Level Up Cleveland" width="336" height="189" src="https://www.youtube.com/embed/OUHLGH8R1a4?feature=oembed" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
</div>
  </section>
