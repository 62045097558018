import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.css']
})
export class ScheduleComponent implements OnInit {
  title='SCHEDULE';
  constructor(private meta: Meta) { }

  ngOnInit(): void {
    this.meta.updateTag({property: 'og:title', content: 'SCHEDULE'});
    this.meta.updateTag({property: 'og:img', content: 'https://www.brianalanhager.com/assets/img/Quarry.png'});
  }

}
