<header class="container-fluid masthead text-center text-black" id="page-top">
  <div class="my-auto">
    <div class="row">
      <div class="col-lg-10 mx-auto pt-5">
          <img src="/assets/img/bhLogo.png">
      </div>
      <div class="col-lg-10 mx-auto">
        <!--<iframe class="youtube-video" title="The Brian Alan Hager Band - Waiting On Nothing - Live at BBC - 2024" src="https://www.youtube.com/embed/clz4DzzxDF8?feature=oembed" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->
        <div class="bg-black-trans text-white m-5 p-4">
            <div class="row">
              <div class="col-md-12 text-left">
                <div class="row">

                 <div class="col-md-8 text-center">

                    <div class="mt-3">Brian Alan Hager is an Indie Rock singer/songwriter, guitarist and vocalist from Cleveland, Ohio. His music fuses a soulful blend of bluesy rock/pop with alternative rock. He is an accomplished guitarist and his live performances often feature improvised jams. He performs his original music with The Brian Alan Hager Band and also performs solo.</div>
                    <div class="mt-3">When he's not performing his original music, you can catch him live playing Rock and Pop covers at wineries, breweries, restaruants and pubs in Ohio, Indiana, Pennsylvania, New York and more. Check out his <a href="https://youtube.com/@brianalanhager">YouTube channel</a> for videos of cover performances.</div>
                    <div class="mt-3">Brian's new album "Rock and Soul", featuring a diverse collection of soulful guitar driven songs, will be released to streaming
                    services in phases. The four song, "Side A", was released on April 19, 2023. "Side B" was release on Feb 5, 2024. The complete album will be release later this year. <a href="https://linktr.ee/brianalanhager" target="_new">Listen on your prefered streaming platform.</a>
                    </div>
                  </div>
                  <div class="col-md-4 text-center">
                    <a href="https://linktr.ee/brianalanhager" target="_new"><img class="img-fluid" src="/assets/img/11e_200.JPEG" height="200" width="200" border="0" class="align-top" alt="Rock and Soul (Side A)"></a>
                  </div>
                </div>
                <app-social></app-social>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</header>

<ng-template #template>
  <div class="modal-header">
     <h4 class="modal-title pull-left">Sample Song List</h4>
     <button type="button"
             class="close pull-right"
             (click)="modalRef.hide()">
         <span aria-hidden="true">&times;</span>
     </button>
  </div>

  <!-- Modal Body -->
  <app-song-list></app-song-list>
</ng-template>
