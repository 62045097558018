<app-top-nav></app-top-nav>
<section id="promo" class="container-fluid bg-dark text-white">
  <div class="container text-center">
    <p class="mb-3">"The Condition Of Things" will be available everywhere on January 24, 2022. <br>It can be purchased on CD before then at this website only. Vinyl available soon.</p>
    <div class="text-center row">
      <div class="container text-center col-sm">
        <a href="https://kunaki.com/sales.asp?PID=PX00NXB6NC" target="_blank"><img class="img-fluid img-responsive" height="300" width="300" src="/assets/img/TheConditionOfThings.jpg" frameborder="0"></a>
        <div class="container text-center mt-3">
        "The Condition Of Things" CD<br>
        $10.00<br>
        <a href="https://kunaki.com/sales.asp?PID=PX00NXB6NC" class="btn btn-info m-2" role="button">Purchase with Credit Card</a>
        <a href="http://Kunaki.com/Sales.asp?PID=PX00NXB6NC&PP=1" class="btn btn-info m-2" role="button">Purchase with Pay Pal</a>
        </div>
      </div>
    </div>
  </div>
</section>
