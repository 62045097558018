import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SingleMainPageComponent } from './single-main-page/single-main-page.component';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { ShopComponent } from './shop/shop.component';
import { PromoComponent } from './promo/promo.component';
import { ScheduleComponent } from './schedule/schedule.component';


const routes: Routes = [
  { path: '', component: SingleMainPageComponent },
  { path: 'products/:productId', component: ProductDetailsComponent },
  { path: 'shop', component: ShopComponent },
  { path: 'promo', component: PromoComponent },
  { path: 'schedule', component: ScheduleComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
