import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-product-alerts',
  templateUrl: './product-alerts.component.html',
  styleUrls: ['./product-alerts.component.css']
})
export class ProductAlertsComponent implements OnInit {

  @Input() product;
  @Output() notify = new EventEmitter();
  constructor() { }

  ngOnInit() {
    const simpleObservable = new Observable((observer) => {
      observer.next("ba ba ba");
      observer.complete();
            
    })

    let subscribtion = simpleObservable.subscribe();
    
    subscribtion.unsubscribe();
  }


  

}