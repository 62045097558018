<section id="music" class="container-fluid bg-dark text-white">
    <div class="container text-center">
      <h2 class="mb-4">Listen</h2>
      Brian's music can be heard on <a href="https://open.spotify.com/artist/7CnSpNEPorm9nQ0Eb7yqCM?si=GDnpMAcPTlyNqAthc7OjqQ" target="_blank" alt="twitter">Spotify</a>,
      <a href="https://music.apple.com/us/artist/brian-alan-hager/1453544466" target="_blank" alt="apple music">Apple Music</a>,
      <a href="https://music.youtube.com/channel/UCoB_-S7GBLGLCjTnvRfHgbQ" target="_blank" alt="you tube music">You Tube Music</a> and most any other streaming platforms.
      <br>CDs are also <a href="http://kunaki.com/mSales.asp?PublisherId=131629">available for purchase here.</a>
      <br>
      <br>
      <iframe src="https://open.spotify.com/embed/playlist/4q4ztGCSllie2xL7AGymQB" width="300" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
    </div>
</section>
