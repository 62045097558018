<section class="container-fluid container" id="events">
<div>
  <div class="row">
    <div class="col-lg-12 text-center">
      <h2 class="section-heading">Upcoming Gigs</h2>
      <br>
      <div *ngFor="let event of eventObj | async">
        <div *ngIf="event.Date >= getTodayISO()">
          <h4>
            <a [title]="event.Date + ' details'">
            {{ formatDate(event.Date) }}
            </a>
          </h4>
          <div *ngIf="event.Artist.includes('Band') || event.Artist.includes('Company') || event.Artist.includes('Joe')">
            {{ event.Artist }}
          </div>
          <div *ngIf="event.Location">
            <a href="https://www.google.com/maps/search/?api=1&query={{ event.Location + ' ' + event.Address + ' ' + event.City + ', ' + event.State }}" target="_new">{{ event.Location }}</a>
          </div>
          <div *ngIf="event.Location">
            {{ event.City + ', ' + event.State }} <span *ngIf="event.Tickets"> - <a href="https://{{ event.Tickets }}" target="_new">Get Tickets</a></span>
          </div>
          <div *ngIf="event.Description">
            {{ event.Description  }}
          </div>
          <br>
        </div>
      </div>
      <br>
      <details>
        <summary>Past Shows</summary><br>
        <div *ngFor="let event of pastEvents">
          <div>
            <h5>
              <a [title]="event.Date + ' details'">
              {{ formatDate(event.Date) }}
              </a>
            </h5>
            <div *ngIf="event.Artist.includes('Band') || event.Artist.includes('Company')">
              {{ event.Artist }}
            </div>
            <div *ngIf="event.Location">
              <a href="https://www.google.com/maps/search/?api=1&query={{ event.Location + ' ' + event.Address + ' ' + event.City + ', ' + event.State }}" target="_new">{{ event.Location }}</a>
            </div>
            <div *ngIf="event.Location">
              {{ event.City + ', ' + event.State }}
            </div>
            <br>
          </div>
        </div>
      </details>
    </div>
  </div>
</div>
</section>

