<section class="container-fluid text-center" id="discography">
        <h2 class="mb-4">Discography / Purchase</h2>
        <h3>Brian Alan Hager</h3>
            <div class="text-muted"><strong>I Don't Feel</strong> - 2019<br>Label: 300 Watt Head<br>
                <span class="theme-text-color-4-3">
                    <u><a href="https://open.spotify.com/track/5Zn1cqhqlK5HQUMEUdnCG4?si=mpyyzmmNRfqGwdqngfqR7g" target="_blank">Listen On Spotify</a></u>
                </span><br>
            </div>
          <div class="text-muted"><strong>The Race</strong> - 2019<br>Label: 300 Watt Head<br><span class="theme-text-color-4-3">
                  <u><a href="https://open.spotify.com/track/2IfEYhr49GtLBJFwksc0Mb?si=8AOdocKFSjq_RRp4mNgZ7Q" target="_blank">Listen On Spotify</a></u>
                  <u><a href="http://kunaki.com/sales.asp?PID=PX00RCD6CK&pp=1"  target="_blank" >Purchase</a></u>
                </span><br>
          </div>
          <br><br>
        <h3>The Tie Downs</h3>
          <div class="text-muted"><strong>Tied Down</strong> - 2019<br>Label: 300 Watt Head<br><span class="theme-text-color-4-3">
                  <u><a href="https://open.spotify.com/artist/3ZzLzWjr806Ld9sjLqWdi1?si=BCQO32bZSkqS45LkP82fqg" target="_blank">Listen On Spotify</a></u><br>
                  <u><a href="http://Kunaki.com/MSales.asp?PublisherId=131629"  target="_blank" >Purchase</a></u>
              </span><br>
          </div>
          <br><br>
        <h3>Travis Haddix</h3>
          <div class="text-muted"><strong>A Dozen Times</strong> - 2011<br>Label: CDS Records<br>&#8203;Credits: Guitar, Engineer<br><span class="theme-text-color-4-3">
                  <u><a href="https://open.spotify.com/album/1z6TKwE5fPjrgftWTCx2eS?si=YoMng6TMSVuExb8cPLb8kg" target="_blank">Listen On Spotify</a></u><br><br>
              </span><br>
          </div>
          <div class="text-muted"><strong>Old Man in Love</strong> - 2011<br>Label: CDS Records<br>&#8203;Credits: Guitar, Engineer<br><span class="theme-text-color-4-3">
                  <u><a href="https://open.spotify.com/album/0E7zYwnxyZIE6W5bsGqY9J?si=ZvpJuJX7Sny5CVCFUWgfVQ" target="_blank">Listen On Spotify</a></u><br><br>
              </span><br>
          </div>
          <div class="text-muted"><strong>Ring Around Her Finger, Rope Around My Neck</strong> - 2013<br>Label: Benevolent Blues<br>&#8203;Credits: Guitar, Engineer<br><span class="theme-text-color-4-3">
                  <u><a href="https://open.spotify.com/album/64IlLYF4Pd1yv6use5Ufbh?si=BC85LBdJQamZq9b69_w2CA" target="_blank">Listen On Spotify</a></u><br><br>
              </span><br>
          </div>
        <br><br>
        <h3>The Chromes</h3>
          <div class="text-muted"><strong>The Chromes</strong> - 2012<br>Label: 300 Watt Head / Love Muffin<br>&#8203;Credits: Vocals, Guitars, Producer/Engineer, Songwriter<br><span class="theme-text-color-4-3">
                  <u><a href="https://play.google.com/store/music/album/The_Chromes_The_Chromes?id=Bj7m2d73z4awvlrh65w2r2pipya"  target="_blank" >Purchase</a></u><br><br>
              </span><br>
          </div>
          <div class="text-muted"><strong>Let It Roll</strong> - 2015<br>Label: Abydos<br>&#8203;Credits: Vocals, Guitar, Songwriter<br><span class="theme-text-color-4-3">
                  <u><a href="https://play.google.com/store/music/album/The_Chromes_Let_It_Roll?id=Bwm362h2ivr3zmtwmadzmmxw6z4" target="_blank" >Purchase</a></u><br><br>
              </span><br>
          </div>
        <br><br>
        <h3>Infinite Twelve</h3>
          <div class="text-muted"><strong>Infinite Twelve</strong> - 2016<br>Label: 300 Watt Head<br>&#8203;Credits: Guitars, Bass, Keyboards, Producer/Engineer, Songwriter<br><span class="theme-text-color-4-3">
                  <u><a href="http://Kunaki.com/MSales.asp?PublisherId=131629"  target="_blank" >Purchase</a></u> <br><br>
              </span><br>
          </div>
        <br><br>
        <h3>Vanity Crash</h3>
            <div class="text-muted"><strong>Vanity Crash</strong> - 2005<br>Label: Grid Records<br>&#8203;Credits: Guitar, Engineer<br><span class="theme-text-color-4-3">
                  <u><a href="https://play.google.com/store/music/album/vanity_Crash_vanityCrash?id=Bavmrg5oc2l2i67tulmxbo73vgq" target="_blank">Purchase</a></u><br><br>
              </span><br>
          </div>
          <div class="text-muted"><strong>Yours, Justice</strong> - 2013<br>Label: Grid Records<br>&#8203;Credits: Guitar, Engineer, Songwriter<br><span class="theme-text-color-4-3">
                  <u><a href="https://play.google.com/store/music/album/Vanity_Crash_Yours_Justice?id=Bxvzxnzkkc7ql3ygpziyvwtzzdi" target="_blank">Purchase</a></u><br><br>
              </span><br>
          </div>
          <div class="text-muted"><strong>Rock n Roll Junkie</strong> - 2014<br>Label: Grid Records<br>&#8203;Credits: Guitar, Songwiter<br><span class="theme-text-color-4-3">
                  <u><a href="https://play.google.com/store/music/album/Vanity_Crash_Rock_n_Roll_Junkie?id=Bmk5lawebywbdnfbejadutlcxfe" target="_blank">Purchase</a></u><br><br>
              </span><br>
          </div>
</section>
