<section class="container-fluid p-0" id="photos">
    <div class="container-fluid p-0">
      <div class="row no-gutters popup-gallery">
        <div class="col-lg-4 col-sm-6">
          <a class="portfolio-box" href="/assets/img/portfolio/fullsize/Brian4.jpg">
            <img class="img-fluid" src="/assets/img/portfolio/thumbnails/Brian4.jpg" alt="">
            <div class="portfolio-box-caption">
              <div class="portfolio-box-caption-content">
                <div class="project-category text-faded">
                  2019 - Miami, FL
                </div>
                <div class="project-name">
                  Brian Alan Hager
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="col-lg-4 col-sm-6">
          <a class="portfolio-box" href="/assets/img/portfolio/fullsize/Brian2016-2.JPG">
            <img class="img-fluid" src="/assets/img/portfolio/thumbnails/Brian2016-2.JPG" alt="">
            <div class="portfolio-box-caption">
              <div class="portfolio-box-caption-content">
                <div class="project-category text-faded">
                  2016 - Cleveland, OH
                </div>
                <div class="project-name">
                  Brian Alan Hager
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="col-lg-4 col-sm-6">
          <a class="portfolio-box" href="/assets/img/portfolio/fullsize/TShirt2.png">
            <img class="img-fluid" src="/assets/img/portfolio/thumbnails/TShirt2.png" alt="">
            <div class="portfolio-box-caption">
              <div class="portfolio-box-caption-content">
                <div class="project-category text-faded">
                  2019 - Cleveland, OH
                </div>
                <div class="project-name">
                  Brian Alan Hager
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </section>
