import { Component } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from 'angularfire2/firestore';
import { events } from '../events';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { Meta } from '@angular/platform-browser';

export interface Events {
  Address: string;
  Artist: string;
  City: string;
  Date: string;
  Description: string;
  Location: string;
  State: string;
  Title: string;
  Zip: string;
  Tickets: string;
}

@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.css']
})
export class EventListComponent {
  public eventCollectionRef: AngularFirestoreCollection<Events>;
  public eventObj: Observable<Events[]>;
  public pastEvents: Events[] = [];

  constructor (private afs: AngularFirestore, private meta: Meta ) {
    this.eventCollectionRef = this.afs.collection('Events', ref => ref.orderBy('Date'));
    this.eventObj = this.eventCollectionRef.valueChanges();
    this.getPastEvents();
  }

  ngOnInit(): void {
    this.meta.updateTag({property: 'og:title', content: 'SCHEDULE'});
    this.meta.updateTag({property: 'og:img', content: 'https://www.brianalanhager.com/assets/img/Quarry.png'});
  }

  formatDate(dateString: string) {
    return moment(dateString).local().format('MMM D, YYYY (ddd) - h:mm A ');
  }

  public getTodayISO() {
    let now = Date.now();
    let todayISO =  moment(now).local().format(moment.defaultFormat);
    return todayISO;
  }

  public createDate(days, months, years) {
    var date = new Date();
    date.setDate(date.getDate() + days);
    date.setMonth(date.getMonth() + months);
    date.setFullYear(date.getFullYear() + years);
    return date;
  }

  public getOneYearAgoISO() {
    // onlhy getting six monthis now
    let oneYearAgoISO =  moment(this.createDate(0,-6,0)).local().format(moment.defaultFormat);
    return oneYearAgoISO;
  }

  private getPastEvents() {
    this.eventObj.subscribe(data => {
      var thePastOnes: Events[] = [];
      const todaysDate = this.getTodayISO();
      const oneYearAgo = this.getOneYearAgoISO();
      data.forEach(function(event) {
        if(event.Date < todaysDate && event.Date > oneYearAgo) {
          thePastOnes.unshift(event);
        }
       })
       this.pastEvents = thePastOnes;
    });

  }

}
